import { Configuration } from "@azure/msal-browser";
import configParams from "../configParams";
import { isIE, isEdge } from "react-device-detect";
import { LogLevel } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_USERNAME_SI",
    forgotPassword: "B2C_1A_USERNAME_PASSWORDRESET",
    editProfile: "b2c_1_edit_profile",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${configParams.SSO_SERVER_NAME}.b2clogin.com/${configParams.SSO_SERVER_NAME}.onmicrosoft.com/${configParams.B2C_1A_USERNAME_SI}`,
    },
    signUpSignInEhiAD: {
      authority: `https://${configParams.SSO_SERVER_NAME}.b2clogin.com/${configParams.SSO_SERVER_NAME}.onmicrosoft.com/B2C_1A_AAD_SINGLE_SI`,
    },
    signUpSignInBBCAD: {
      authority: `https://${configParams.SSO_SERVER_NAME}.b2clogin.com/${configParams.SSO_SERVER_NAME}.onmicrosoft.com/B2C_1A_BBC_SI`,
    },
    forgotPassword: {
      authority: `https://${configParams.SSO_SERVER_NAME}.b2clogin.com/${configParams.SSO_SERVER_NAME}.onmicrosoft.com/B2C_1A_USERNAME_PASSWORDRESET`,
    },
    editProfile: {
      authority: `https://${configParams.SSO_SERVER_NAME}.b2clogin.com/${configParams.SSO_SERVER_NAME}.onmicrosoft.com/b2c_1_edit_profile`,
    },
  },
  authorityDomain: `${configParams.SSO_SERVER_NAME}.b2clogin.com`,
};

export const protectedResources = {
  apiHello: {
    endpoint:
      "https://uk-ses-b2c-sandbox-ne.azurewebsites.net/api/usersmanagement/createuser",
    SignInsLogEndpoint:
      "https://uk-ses-b2capi-sandbox-ne.azurewebsites.net/api/Audit",
    DirectoryLogEndpoint:
      "https://uk-ses-b2capi-sandbox-ne.azurewebsites.net/api/Audit/1",
    scopes: [
      `https://${configParams.SSO_SERVER_NAME}.onmicrosoft.com/${configParams.SSO_TOKEN_APP_ID}/TokenScope`,
    ],
    graphapiScopes: ["https://graph.microsoft.com/User.Read.All"], // e.g. api://xxxxxx/access_as_user
  },
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: configParams.SSO_CLIENT_ID, //"a8a55f77-347d-4dd5-a164-7a22727f94ae",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    //protocolMode: ProtocolMode.OIDC,
    navigateToLoginRequestUrl: false,
    redirectUri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ":" + window.location.port : ""
    }`,
    postLogoutRedirectUri: `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? ":" + window.location.port : ""}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge, // Set this to  isIE || isEdge ("true" for IE /Edge) if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
  graphMailEndpoint: "https://graph.microsoft-ppe.com/v1.0/me/messages",
};
export const popConfig = {
  endpoint: "https://signedhttprequest.azurewebsites.net/api/validateSHR",
};

export const ssoLoginRequest = {
  scopes: protectedResources.apiHello.scopes,
  prompt: "login",
};

export const ssoLoginADRequest = {
  scopes: protectedResources.apiHello.scopes,
  extraQueryParameters: { domain_hint: "enterprise" },
};

export const ssoLoginBBCRequest = {
  scopes: protectedResources.apiHello.scopes,
  extraQueryParameters: { domain_hint: "bbc" },
};

export const silentRequest = {
  scopes: [protectedResources.apiHello.scopes],
  //account: account,
  prompt: "select_account",
};

export const logoutRequest = {};

// Setting this to 5 mins, meaning that the ETD sessionTtimeout dialog will be populated
// when jwt access token will be expired in 5 mintues.
export const jwtTimeoutDialogThresholdInMinutes = 5;

export const carClubSessionTimeoutThresholdInMinutes = 13;
